import * as React from 'react'
import { Tag } from 'src/design-system'
import { PencilSimple } from '@phosphor-icons/react'
import { observer } from 'mobx-react-lite'
import { store } from 'store/index'
import { skillEditableByLevelDescriptions } from './skill-modal/info-pane/editable-by-info-line'

type SkillEditableByLabelProps = {
  id?: string
}

export const SkillEditableByLabel: React.VFC<SkillEditableByLabelProps> =
  observer(({ id }) => {
    const skill = store.skills.byId(id)

    if (!skill || !id || !skill.org) return null

    const currentUser = store.currentUser

    if (
      currentUser &&
      !currentUser.isAdminOfOrg(skill.org.id) &&
      !currentUser.isEditorOfOrg(skill.org.id)
    )
      return null

    return (
      <Tag variant="paper">
        <PencilSimple className="mr-1" />
        <div className="text-gray-900 font-normal">
          {skillEditableByLevelDescriptions(
            skill?.editableBy || 'admin_editors'
          )}
        </div>
      </Tag>
    )
  })
